/**
 * VideoPlayerSLDP
 * https://softvelum.com/player/web/
 */

import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import { PlayerResolution } from 'src/core/types/player'
import { delay } from 'src/core/utilities/delay'

import { SLDP, SLDPPlayer } from 'public/vendor/softvelum/SLDP'

import styles from './VideoPlayerSLDP.module.css'

// FIXME dvr - fix toggling not working at all window sizes
// FIXME dvr - fix toggling dvr too fast
// FIXME dvr - use local hls.js

// FIXME refactor loaded & mounted
// FIXME update `SLDP.d.ts` with latest additions/changes from: https://softvelum.com/player/web/

declare global {
  interface Window {
    SLDP: SLDP
  }
}

export type VideoPlayerSLDPStatus = {
  currentResolution?: string
  currentTime: number
  duration: number
  dvr: boolean
  error?: string
  fps?: number
  playing: boolean
}

export const DEFAULT_VIDEO_PLAYER_SLDP_STATUS: VideoPlayerSLDPStatus = {
  currentTime: 0,
  duration: 0,
  dvr: false,
  playing: false
}

export interface VideoPlayerSLDPProps {
  abr?: boolean
  advancedParameters?: any
  audioLevel?: boolean
  buffer?: number
  controls?: boolean
  dvr?: boolean
  dvrUrl?: string;
  dvrTime?: number;
  hidden?: boolean;
  id: string
  imageData?: boolean
  muted?: boolean
  onAudioLevelChange?: (level: number) => void
  onImageDataChange?: (imageData: ImageData) => void
  onStatusChange?: (status: VideoPlayerSLDPStatus) => void
  play?: boolean
  resolution?: PlayerResolution
  url: string
  volume?: number
}

const VideoPlayerSLDP = (props: VideoPlayerSLDPProps) => {
  const {
    abr,
    advancedParameters = {},
    audioLevel = false,
    buffer = 1000,
    controls = false,
    dvr = false,
    dvrUrl,
    dvrTime,
    hidden,
    id,
    imageData,
    muted,
    onAudioLevelChange,
    onImageDataChange,
    onStatusChange,
    play,
    resolution,
    url,
    volume = 1
  } = props

  const mounted = useRef(false)
  const audioLevelRef = useRef<boolean>(audioLevel)
  const containerRef = useRef<HTMLDivElement>(null)

  const frameCountRef = useRef<number>()
  const loadedRef = useRef<boolean>(false)
  const mutedRef = useRef<boolean>(!!muted)
  const playerRef = useRef<SLDPPlayer>()
  const playingRef = useRef(false)
  const volumeRef = useRef<number>(volume)

  const [currentResolution, setCurrentResolution] = useState<string>()
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState<number>(0)
  const [error, setError] = useState<string>()
  const [fps, setFps] = useState<number>()
  const [playing, setPlaying] = useState(false)

  useEffect(() => { playingRef.current = playing }, [playing])

  const containerId = `sldpPlayer-${id}`

  /**
   * utilities
   */

  const getResolution = (string?: string): PlayerResolution | undefined => {
    switch (string) {
      case '1920p':
        return '1920p'
      case '1080p':
        return '1080p'
      case '720p':
        return '720p'
      case '540p':
        return '540p'
      case '480p':
        return '480p'
      case '360p':
        return '360p'
      default:
        return undefined
    }
  }

  /**
   * update player volume considering `muted` & `volume` props
   */
  const updatePlayerVolume = (): void => {
    // console.log(`VideoPlayerSLDP[${id}] - updatePlayerVolume - muted:', mutedRef.current, 'volume:', volumeRef.current)
    if (!loadedRef.current) return
    if (!playerRef.current) return
    playerRef.current.setVolume(mutedRef.current ? 0 : Math.round(volumeRef.current * 100))
  }

  /**
   * load
   */

  const loaded = () => {
    console.log(`VideoPlayerSLDP[${id}] - loaded`)
    playerRef.current?.destroy()
    playerRef.current = window.SLDP.init({
      // https://softvelum.com/player/web/#params
      container: containerId,
      stream_url: url,
      autoplay: true,
      muted: true,
      width: 'parent',
      height: 'parent',
      initial_resolution: abr ? undefined : getResolution(resolution),
      buffering: buffer,
      offset: 4,
      latency_tolerance: 300,
      latency_adjust_method: 'seek', // fast-forward
      // splash_screen
      adaptive_bitrate: abr
        ? {
          initial_rendition: getResolution(resolution),
          max_rendition: getResolution(resolution),
          size_constrained: false
        }
        : false,
      // pause_timeout
      key_frame_alignment: true,
      controls: true, // required for dvr
      // audio_only
      // audio_title
      reconnects: 999999999999,
      // muteable
      // fullscreen
      // ios_failback_app_url
      // ios_failback_scheme
      // ios_failback_secure_scheme
      screenshots: imageData ? { rate: 30 } : undefined,
      // sync_buffer
      vu_meter: {
        api: 'AudioWorklet',
        // container
        mode: 'peak',
        type: 'input',
        rate: 10
        // db_range
      },
      // aspect_ratio
      ...(dvrUrl && { vod: { url: dvrUrl } }),
      ...advancedParameters
    })
    playerRef.current.setCallbacks({
      onConnectionStarted: url => console.log(`VideoPlayerSLDP[${id}] - player - onConnectionStarted - url:`, url),
      onConnectionEstablished: streams => {
        console.log(`VideoPlayerSLDP[${id}] - player - onConnectionEstablished - streams:`, streams)
        const rendition = playerRef.current?.getCurrentRendition()
        setCurrentResolution(rendition)
        setError(undefined)
        setPlaying(true)
      },
      onVolumeSet: volume => console.log(`VideoPlayerSLDP[${id}] - player - onVolumeSet - volume:`, volume),
      onConnectionClosed: () => {
        console.log(`VideoPlayerSLDP[${id}] - player - onConnectionClosed`)
        setPlaying(false)
      },
      onError: error => {
        console.log(`VideoPlayerSLDP[${id}] - player - onError - error:`, error)
        setError(error)
      },
      onChangeRendition: (rendition, name) => console.log(`VideoPlayerSLDP[${id}] - player - onChangeRendition - rendition:`, rendition, 'name:', name),
      onChangeRenditionComplete: (rendition, name) => {
        console.log(`VideoPlayerSLDP[${id}] - player - onChangeRenditionComplete - rendition:`, rendition, 'name:', name)
        setCurrentResolution(rendition)
      },
      onLatencyAdjustSeek: (from, to) => console.log(`VideoPlayerSLDP[${id}] - player - onLatencyAdjustSeek - from:`, from, 'to:', to),
      onLowBuffer: () => console.log(`VideoPlayerSLDP[${id}] - player - onLowBuffer`),
      onScreenshotReady: (imageData, _presentationTimestamp) => {
        // console.log(`VideoPlayerSLDP[${id}] - player - onScreenshotReady - imageData:`, imageData, 'presentationTimestamp:', presentationTimestamp)
        if (!imageData || !onImageDataChange) return
        onImageDataChange(imageData)
      },
      onVUMeterUpdate: (magnitudes, _decibels) => {
        // console.log(`VideoPlayerSLDP[${id}] - player - onVUMeterUpdate - magnitudes:`, magnitudes, 'decibels:', _decibels)
        if (!audioLevelRef.current || !onAudioLevelChange) return
        let level = _.max(magnitudes) || 0
        level *= 2
        level = _.clamp(level, 0, 1)
        onAudioLevelChange(level)
      }
    })
    updatePlayerVolume()
    setTimeout(() => (loadedRef.current = true))
  }

  const load = async () => {
    console.log(`VideoPlayerSLDP[${id}] - load - mounted.current:`, mounted.current, ' url:', url)
    // TESTING: halt if this component has been unmounted since (or just before) this was (re)called
    if (!mounted.current) {
      console.log(`VideoPlayerSLDP[${id}] - load - COMPONENT UNMOUNTED - HALT/SKIP <<<<`)
      return
    }
    if (!document.getElementById('sldp')) {
      const script = document.createElement('script')
      script.id = 'sldp'
      script.src = '/vendor/softvelum/sldp-v3.0.0_sdk_fc8126b5.min.js'
      document.body.appendChild(script)
    }
    while (window.SLDP === undefined && mounted.current) {
      console.log(`VideoPlayerSLDP[${id}] - load - loading…`)
      await delay()
    }

    // fix `Cannot read properties of null (reading 'getBoundingClientRect')` error - wait until container dom element exists
    // TODO: should we also add a timeout & error out if its reached? how to handle that error scenario if so? (we still hit it sporadically where we seem to trigger the below 'waiting..' infinitely)
    // UPDATE: now we've added the `mounted` check here & above/below it might not be needed, but we could consider an increasing delay at least so it doesn't fire constantly at 50ms forever just incase we manager to retrigger somehow...
    while (!document.getElementById(containerId) && mounted.current) {
      console.log(`VideoPlayerSLDP[${id}] - load - waiting…`)
      await delay()
    }

    // TESTING: halt if this component has been unmounted since (or just before) this was (re)called
    // NB: added this second check just incase the component was unmounted while one of the above while loops was running
    if (!mounted.current) {
      console.log(`VideoPlayerSLDP[${id}] - load - COMPONENT UNMOUNTED - HALT/SKIP <<<<`)
      return
    }

    loaded()
  }

  // NB: used to reload the player for parms that can't be changed on an existing instance (e.g. changing abr/resolution, which changes the url, which seems to need a new sldp player instance)
  const reload = async () => {
    console.log(`VideoPlayerSLDP[${id}] - reload`)
    playerRef.current?.pause() // TODO #798 mute test
    playerRef.current?.destroy()
    playerRef.current = undefined
    setTimeout(load, 500)
  }

  useEffect(() => {
    // mount
    console.log(`VideoPlayerSLDP[${id}] - mount`)
    mounted.current = true
    load()
    // unmount
    return () => {
      console.log(`VideoPlayerSLDP[${id}] - unmount`)
      mounted.current = false
      playerRef.current?.pause() // TODO #798 mute test
      playerRef.current?.destroy()
    }
  }, [])

  /**
   * dvr
   * sldp player doesn't have an api for entering/exiting dvr mode so we simulate ui interactions
   */
  useEffect(() => {
    setTimeout(() => {
      if (!dvrUrl || !loadedRef.current) return
      if (!containerRef.current) throw new Error('no container element')
      const thumb: HTMLElement | undefined =
        _.first(containerRef.current.getElementsByClassName('sldp-progress-thumb')) as HTMLElement | undefined
      if (!thumb) throw new Error('no thumb element')
      const video: HTMLVideoElement | null = containerRef.current.querySelector('video')
      if (!video) throw new Error('no video element')
      if (dvr) {
        console.log('VideoPlayerSLDP - dvr - enable')
        thumb.dispatchEvent(new Event('mousedown', { bubbles: true }))
        thumb.dispatchEvent(new Event('mouseup', { bubbles: true }))
        if (_.includes([0, NaN, Infinity], video.currentTime)) throw new Error('enable dvr failed')
      } else {
        console.log('VideoPlayerSLDP - dvr - disable')
        thumb.dispatchEvent(new Event('mousedown', { bubbles: true }))
        thumb.style.left = `${containerRef.current.clientWidth}px`
        thumb.dispatchEvent(new Event('mouseup', { bubbles: true }))
        if (!_.includes([0, NaN, Infinity], video.currentTime)) throw new Error('disable dvr failed')
      }
    }, 300) // after parent layout changes
  }, [dvr])

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log(`VideoPlayerSLDP[${id}] - player volume fail-safe - interval`)
      updatePlayerVolume()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  /**
   * props
   */

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - useEffect - audioLevel:`, audioLevel)
    audioLevelRef.current = audioLevel
  }, [audioLevel])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - useEffect - dvrTime:`, dvrTime)
    if (!containerRef.current || !dvr || dvrTime === undefined) return
    const video: HTMLVideoElement | null = containerRef.current.querySelector('video')
    if (!video) return
    video.currentTime = dvrTime
  }, [dvrTime])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - useEffect - imageData:`, imageData)
    if (!loadedRef.current) return
    load()
  }, [imageData])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - muted:`, muted)
    mutedRef.current = !!muted
    updatePlayerVolume()
  }, [muted])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - play:`, play)
    if (!loadedRef.current) return
    play ? playerRef.current?.play() : playerRef.current?.pause()
  }, [play])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - useEffect - abr:`, abr)
    if (!loadedRef.current) return
    reload()
  }, [abr])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - useEffect - resolution:`, resolution)
    if (!loadedRef.current || !resolution) return
    playerRef.current?.changeRendition(resolution)
  }, [resolution])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - url:`, url)
    if (!loadedRef.current) return
    playerRef.current?.setStreamURL(url)
  }, [url])

  useEffect(() => {
    console.log(`VideoPlayerSLDP[${id}] - volume:`, volume)
    volumeRef.current = volume
    updatePlayerVolume()
  }, [volume])

  /**
   * events
   */

  useEffect(() => {
    const interval = setInterval(() => {
      if (!containerRef.current) return
      const video: HTMLVideoElement | null = containerRef.current.querySelector('video')
      if (!video) return

      // current time
      const newCurrentTime: number = video.currentTime
      // console.log(`VideoPlayerSLDP[${id}] - currentTime:`, newCurrentTime)
      setCurrentTime(newCurrentTime)

      // duration
      const newDuration: number = video.duration
      // console.log(`VideoPlayerSLDP[${id}] - duration:`, newDuration)
      setDuration(newDuration)

      // frame count
      const frameCount: number | undefined = (video as any).webkitDecodedFrameCount
      // console.log(`VideoPlayerSLDP[${id}] - frameCount:`, frameCount)
      if (!frameCount) return
      if (frameCountRef.current !== undefined) {
        const fps = frameCount - frameCountRef.current
        // console.log(`VideoPlayerSLDP[${id}] - fps:`, fps)
        setFps(fps)
      }
      frameCountRef.current = frameCount
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!onStatusChange) return
    onStatusChange({
      currentResolution,
      currentTime,
      duration,
      dvr: !_.includes([0, NaN, Infinity], duration),
      error,
      fps,
      playing
    })
  }, [currentResolution, currentTime, duration, error, fps, playing])

  // useEffect(() => {
  //   console.log(`VideoPlayerSLDP[${id}] - useEffect - url:`, url)
  // }, [url])

  /**
   * render
   */

  return (
    <div
      className={`${styles.container} ${!controls ? styles.controlsHidden : ''} ${hidden ? styles.hidden : ''}`}
      data-test-id="ark-video-player-sldp" // e2e testing identifier
      id={containerId}
      ref={containerRef}
    />
  )
}

export default VideoPlayerSLDP
